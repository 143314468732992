import React from 'react'
import { Wrap } from '../components/page/components'
import { compose } from 'recompose'
import { Flex, Box } from 'reflexbox/styled-components'
import { withAuthorization } from '../components/session'
import SEO from '../components/SEO'
import AppHeader from '../components/global/app-header'
import AppBody from '../components/page/app-body'
import AppMenu from '../components/page/app-menu'
import TermsBody from '../components/terms/body'

const PageBase = ({ authUser, location }) => {
  return (
    <Wrap>
      <SEO title="Terms of Use" />
      <AppHeader
        title="Terms of Use"
        authUser={authUser}
        backUrl="/graphic-design"
        hideSearch={true}
      />
      <AppBody>
        <Flex flexWrap="nowrap">
          <Box width={[1, 5 / 6, 1]} ml={0}>
            <TermsBody />
          </Box>
        </Flex>
      </AppBody>
      <AppMenu pathname={location.pathname} authUser={authUser} />
    </Wrap>
  )
}

const condition = authUser => !!authUser
const Page = compose(withAuthorization(condition))(PageBase)
export default Page
