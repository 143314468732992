import React from 'react'
import { Flex, Box } from 'reflexbox'
import { Link } from 'gatsby'
import { Copy, ButtonLink, SubTitle } from '../page/components'

export default () => {
  return (
    <Flex flexWrap="nowrap">
      <Box width="100%">
        <Copy>
          <p>
            The Guide is a online platform that contains reference material for
            the Shillington course. The Guide has over 20 years of content
            created by 100s of teachers from every Shillington campus.
          </p>
          <ButtonLink>
            <a href="https://www.shillingtoneducation.com/">
              Visit Shillington Education
            </a>
          </ButtonLink>
          <br />
          <br />
          <br />
          <br />
        </Copy>

        <SubTitle>Terms</SubTitle>

        <Copy>
          <h4>Terms of Service</h4>
          <p>
            Welcome to Theguide.design, Shillington&rsquo;s online learning
            resource and community. Theguide.design (the &ldquo;Service&rdquo;)
            are owned, operated and distributed by Shillington College Ltd &amp;
            Shillington School of Graphic Design LLC (referred to in these Terms
            of Service as &ldquo;Shillington&rdquo; or &ldquo;we&rdquo; and
            through similar words such as &ldquo;us,&rdquo; &ldquo;our,&rdquo;
            etc.). By accessing any part of the Service, you are agreeing to the
            terms and conditions described below. If you do not agree to any of
            these terms, you should not use the Service. We may, at our sole
            discretion, modify these Terms of Service at any time.
          </p>

          <h4>Description of Service</h4>
          <p>
            The Theguide.design allows students to access course materials,
            communicate, collaborate, and complete assessments. We reserve the
            right to refuse to provide the Service to any person for any reason
            and/or to discontinue the Service in whole or in part at any time,
            with or without prior notice.{' '}
          </p>

          <h4>Copyright, Licenses and User Submissions</h4>
          <p>
            The entire contents of Theguide.desgn are protected by international
            copyright and trademark laws and other proprietary rights.
          </p>
          <ul>
            <li>
              YOU MAY NOT MODIFY, COPY, REPRODUCE, REPUBLISH, UPLOAD, POST,
              TRANSMIT, OR DISTRIBUTE, IN ANY MANNER, THE MATERIAL ON THE
              SERVICE, INCLUDING TEXT, GRAPHICS, CODE AND/OR SOFTWARE.
            </li>
            <li>
              You may print and download portions of material from the different
              areas of the Service solely for your own non-commercial use.
            </li>
          </ul>

          <ul>
            <li>
              You may not use data mining, data gathering or extraction tools or
              manual processes to collect, gather or copy any content or data on
              or related to the Service in a manner not authorized by
              Theguide.design in writing.
            </li>

            <li>
              You may not engage in practices of &ldquo;screen scraping,&rdquo;
              &ldquo;database scraping&rdquo; or any other practice or activity
              the purpose of which is to obtain lists of users, portions of a
              database, or other lists or information from the Service, in any
              manner and any quantities not authorized by Theguide.design in
              writing.
            </li>

            <li>
              You may not frame or utilize framing techniques to enclose any
              trademark, logo or other proprietary information (including
              images, text, page layout or form) of Theguide.design or its
              affiliates without express written consent.
            </li>

            <li>
              You may not use meta tags or any other &ldquo;hidden text&rdquo;
              utilizing the Theguide.design name or trademarks without the
              express written consent of Theguide.design.
            </li>
          </ul>

          <h4>Account Terms</h4>
          <p>
            The following terms apply to your use of the Service and any account
            that you may open or attempt to open via the Service:
          </p>

          <ul>
            <li>
              You represent and warrant that you are at least 16 years of age.
              If you are under age 16, you may not, under any circumstances or
              for any reason, use the Service.
            </li>

            <li>
              You are responsible for maintaining the security of your account
              and password. We view any actions taken by your account as taken
              by you. Theguide.design will not be liable for any loss or damage
              from your failure to comply with this security obligation.
            </li>

            <li>
              You must not share your account or password with any other person.
              Violation of this will result in immediate suspension of access.
            </li>
          </ul>

          <h4>Changing Fees</h4>
          <p>
            We reserve the right, at any time, to change any fees or charges for
            using any services provided on the Service (or to begin charging
            fees for any free service), provided that such changes will not
            apply to fees or charges paid or free services used prior to the
            time of the change.
          </p>

          <h4>Rules for All Users</h4>
          <p>
            Theguide.design is a community of Shillington learners. When you
            sign up for the Site, you agree to the following rules:
          </p>

          <ul>
            <li>
              You will use the Service only for lawful purposes and agree to not
              use the Service in any way that will infringe upon the use or
              rights of any other user.
            </li>

            <li>
              Your use of the Service is subject to all applicable laws and
              regulations, and you are solely responsible for the substance of
              your communications through the Service.
            </li>

            <li>
              You agree that you will not engage in any behavior that
              constitutes unauthorized or unsolicited advertising, junk or bulk
              email (also known as &ldquo;spamming&rdquo;), chain letters, any
              other form of unauthorized solicitation, or any form of lottery or
              gambling.
            </li>

            <li>
              You will not share content from the Service with anyone who is not
              properly licensed to access the content.
            </li>

            <li>
              You will not upload, share, post, distribute or otherwise partake
              in any behavior that is unlawful, threatening, abusive, harassing,
              defamatory, libelous, deceptive, fraudulent, invasive of
              another&rsquo;s privacy, tortious, contains explicit or graphic
              descriptions or accounts of sexual acts (including but not limited
              to sexual language of a violent or threatening nature directed at
              another individual or group of individuals), or otherwise violates
              our rules or policies.
            </li>

            <li>
              You will not partake in any behavior that victimizes, harasses,
              degrades, or intimidates an individual or group of individuals on
              the basis of religion, gender, sexual orientation, race,
              ethnicity, age, or disability.
            </li>

            <li>
              You will not share software viruses or any other computer code,
              files, or programs that are designed or intended to disrupt,
              damage, or limit the functioning of any software, hardware, or
              telecommunications equipment or to damage or obtain unauthorized
              access to any data or other information of any third party.
            </li>

            <li>
              You will not impersonate any person or entity, including any of
              our employees or representatives.
            </li>

            <li>
              You will not use your account to breach security of another
              account or attempt to gain unauthorized access to another network
              or server. Not all parts of the Service may be available to you or
              other authorized users of the Service. You shall not interfere
              with anyone else&rsquo;s use and enjoyment of the Service. Users
              who violate systems or network security may incur criminal or
              civil liability.
            </li>

            <li>
              You will follow all reasonable rules set by a teacher with respect
              to a class, and you will not take any actions to interfere with
              the teacher or any other students in the class.
            </li>

            <li>
              You may access class content for your information and personal use
              only.
            </li>

            <li>
              Theguide.design reserves the right to delete or suspend your
              account if you violate any of our rules, or at any time and for
              any reason, without any advanced warning.
            </li>
          </ul>

          <h4>Data Policy</h4>
          <p>
            When students enroll in a class with Shillington and sign up for
            Theguide.design, they will have to provide personal information for
            their profile. By doing this, they grant us permission to share
            their profile and information with the Shillington Teachers. It will
            be used for the purpose of teaching the students via
            Theguide.design, as well as tracking their progression &amp;
            development throughout the course.{' '}
          </p>

          <h4>Participating on Theguide.design</h4>
          <p>
            These Terms of Service and the Governing Documents are in addition
            to any agreements, releases or other documents (the &ldquo;Class
            Agreements&rdquo;) that a student may enter into in connection with
            a class, including any Enrollment Agreements entered into through
            Shillington.
          </p>

          <h4>Membership Fees</h4>
          <p>
            Enrollment as a student at Shillington gives access to the Service
            for the period that the student is studying only. Continued access
            will only be available by signing up as a Paid Member after your
            finish your course at Shillington. We do not offer refunds for Paid
            Annual Plans after the initial 24 hour cooling off period. We do not
            offer refunds for Paid Monthly Plans or any other fees collected by
            the Service. Fees for Paid Memberships or any other portion of the
            Service may be changed at any time without prior notice.
          </p>

          <h4>Cancellations and Refunds</h4>
          <p>
            The Guide is not responsible or liable for any refunds for Paid
            Monthly Memberships, workshops, or other purchases on the Service.
            Paid Annual Plans offer a 24 hour cooling off period to receive a
            full refund. After the 24 cooling off period we offer no refund for
            Paid Annual Plans.
          </p>

          <h4>Theguide.design Paid Membership</h4>
          <p>
            As a Shillington graduate, you may participate in
            &ldquo;Theguide.design Paid Membership&rdquo; by subscribing to pay
            monthly or annual membership fees for unlimited access to
            Shillington Course material.
          </p>

          <h4>Recurring Billing</h4>
          <p>
            The membership fee will be billed at the beginning of the paying
            portion of your membership and each month or year thereafter unless
            and until you cancel your membership. We automatically bill your
            Payment Method each month on the calendar day corresponding to the
            commencement of your paying membership. We reserve the right to
            change the timing of our billing, in particular, as indicated below,
            if your Payment Method has not successfully settled. In the event
            your paying membership began on a day not contained in a given
            month, we may bill your Payment Method on a day in the applicable
            month or such other day as we deem appropriate. For example, if you
            started your Premium Membership or became a paying member on March
            31st, your next payment date is likely to be April 30th, and your
            Payment Method would be billed that date.
          </p>

          <h4>No Refunds</h4>
          <p>
            We do not offer refunds for Paid Annual Plans after the initial 24
            hour cooling off period. We do not offer refunds for Paid Monthly
            Plans or any other fees collected by the Service. There are no
            refund or credits for partially used periods. Cancellation is
            immediate and you will no longer have access to The Guide.
          </p>

          <h4>Cancellations</h4>
          <p>
            You may cancel your Paid Membership to The Guide at any time for any
            reason. To cancel your recurring subscription, please visit Cancel
            Account, within your <Link to="/account">account profile</Link>. If
            you have canceled your Membership, you will lose access to all
            features and Membership immediately. If you choose to sign back up
            as a paying member, your billing date will reset to the day you
            paid.
          </p>

          <h4>Price Changes</h4>
          <p>
            We reserve the right to adjust pricing for our service, or any
            components thereof in any manner and at any time as we may determine
            in our sole and absolute discretion. Except as otherwise expressly
            provided for in these Terms of Use, any price changes to your
            service will take effect following email notice to you.
          </p>

          <h4>Theguide.design Rights</h4>
          <p>Theguide.design reserves these rights:</p>

          <ul>
            <li>
              We can make changes to the Theguide.design Site and Services
              without notice or liability.
            </li>

            <li>
              We have the right to terminate your membership, account, payment,
              or other affiliation with the Service at any time and for any
              reason. We can change our eligibility criteria at any time. If
              these things are prohibited by law where you live, then we revoke
              your right to use Theguide.design in that jurisdiction.
            </li>

            <li>
              We will cooperate fully with investigations of violations of
              systems or network security at other sites, including cooperating
              with law enforcement authorities in investigating suspected
              criminal violations.
            </li>

            <li>
              We have the right to reject, cancel, interrupt, remove, or suspend
              any class, comment, or other posted comment at any time and for
              any reason.
            </li>
          </ul>

          <p>
            Theguide.design is not liable for any damages as a result of any of
            these actions, and it is our policy not to comment on the reasons
            for any such action.
          </p>
        </Copy>
      </Box>
    </Flex>
  )
}
